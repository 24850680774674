import * as React from "react"
import { Accordion } from "./accordion"


export const Faq = () => {
  const config  = [
    {
      title:' Сколько времени понадобится на&nbsp;ремонт офиса?',
      text:'<p>От&nbsp;6-ти до&nbsp;12-ти месяцев. Важно также понимать, что речь идет о&nbsp;создании уникального интерьера по&nbsp;индивидуальному проекту, не&nbsp;путать с&nbsp;так называемым &laquo;евроремонтом&raquo;.</p>'
    },
    {
      title:' Что мы понимаем под современным офисом',
      text:'<p>В офисе обязательно должен быть предусмотрена возможность обслуживания всех систем: отопления, водоснабжения, вентиляции, электрооборудования и обеспечен удобный доступ ко всем техническим коммуникациям, что в свою очередь накладывает некоторые ограничения на дизайнерские решения. Сами поверхности подвергаются усиленному износу, что склоняет нас к использованию более практичных и надежных решений. При проектировании, мы учитываем не только законы эргономики, но все возможные требования, предъявляемые к инсоляции помещений и правильной организации рабочих мест.</p><p>Мы&nbsp;создаем индивидуальные дизайнерские пространства для работы и&nbsp;продумываем интерьер до&nbsp;мельчайших подробностей. К&nbsp;общественному пространству предъявляются повышенные требования по&nbsp;пожарной безопасности, санитарным и&nbsp;экологическим нормам.</p>'
    },
  ]
  return (
      <Accordion  config={config}/>
  )
}